import image from '../../images/sad-people.jpg'
import gavel from '../../images/icons/gavel-solid.svg'
import dollar from '../../images/icons/dollar-sign-solid.svg'
import hand from '../../images/icons/hand-holding-dollar-solid.svg'
import stopwatch from '../../images/icons/stopwatch-solid.svg'
import question from '../../images/icons/question-solid.svg'
import Fade from 'react-reveal/Fade';

const MoneyProblems = () => {
    const moneyProblemsData = [
        {id: 1,
        icon: hand,
        text: "You're unsure how much you can pay yourself."},
        {id: 2,
        icon: gavel,
        text: "You can't remember when your next CRA payment is due."},
        {id: 3,
        icon: question,
        text: "You wonder if you are worth being paid what you are worth."},
        {id: 4,
        icon: stopwatch,
        text: "You can't find time to tackle that pile of bookkeeping tasks."},
    ]
    return (
        <div className="fullscreen">
            <Fade bottom>
            <div className="text-center font-72 bold">Paying yourself shouldn't be this stressful</div>

    


            <div className="container">
                <div className="row h-100">
                    

                    <div className="col-md-12 col-lg-4 h-100 img-response justify-content-center">
                        <img src={image} className="mx-lg-auto img-fluid rounded-5 py-3" alt="Sad Business Owner" width="800" height="500" loading="lazy"/>

                        
                    </div>
                    
                    <div className="col-md-12 col-lg-8">
                        <div className="container">
                            <div className="row">
                            {moneyProblemsData.map((item) => {
                            return <div className="col-6"> 
                            <div key={item.id} className="card mb-3 py-5 rounded-5 border-0 text-center">
                                            <div className="mb-3">
                                                <img src={item.icon} className="mx-lg-auto img-fluid rounded-5 mx-auto" alt="Hand with dollar sign" width="50" height="50" loading="lazy"/>
                                            </div>
                                        <h5 className="card-title font-28 mx-3">{item.text}</h5>
                
                                     </div>
                                     </div>
                        })}
                            </div>

                        </div>

                      

                    </div>


                   
                 
                </div>
            </div>
            </Fade>
      </div>
    )
}

const moneyproblems = [
    {
        id: 1,
        icon: hand,
        desc: "You're are unsure how much you can pay yourself."
    },
    {
        id: 2,
        icon: question,
        desc: "You wonder if you are being paid what you are worth."
    },
    {
        id: 3,
        icon: gavel,
        desc: "You can't remember when your next CRA payment is due."
    },
    {
        id: 4,
        icon: stopwatch,
        desc: "You can't find the time to tackle that pile of bookkeeping tasks."
    },
]
export default MoneyProblems;