import About from '../components/About'


const AboutView = () => {
    return (
      <div>
      <About />
      </div>
      
    )
  }

  export default AboutView;