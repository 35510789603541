import React from 'react'
import BlankSpace from '../../components/BlankSpace';
import PDF from '../../images/HCD Bookkeeping Plan.pdf';


export default function BookkeepingPlan() {
  return (
    <div className="fullscreen">
        {/* <BlankSpace/> */}
        <div className="p-5 text-center">
            <a href={PDF} target="_blank" rel="noreferrer">

        <button className="btn btn-primary text-white">Download Bookkeeping Plan PDF</button>
            </a>

        </div>
        <div className="bookkeepingvideo px-5">
            <iframe src="https://www.loom.com/embed/a809932f21f94e3c9b587d4cb4e507da" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen className="bookkeepingvideoiframe" title="hcd-bookkeeping-plan"></iframe></div>
        
        <BlankSpace/>
    </div>
  )
}
