import React from 'react';
import BlankSpace from '../../components/BlankSpace';

export default function DextDemo() {
  return (
        <div className="fullscreen">
        {/* <BlankSpace/> */}
        <div className="p-5 text-center">
            <h1><strong>Receipt Reimbursement Demo</strong></h1>
            {/* <a href={PDF} target="_blank">

        <button className="btn btn-primary text-white">Download Bookkeeping Plan PDF</button>
            </a> */}

        </div>
        <div className="bookkeepingvideo px-5">
            <iframe src="https://www.loom.com/embed/82ddcab57f3a40e2a8fe93c42abdab81" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen className="dextvideoiframe" title="dext-demo"></iframe></div>
        
        <BlankSpace/>
    </div>
  )
}


