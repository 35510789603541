import './components/css/App.css';
import ContactForm from './components/ContactForm'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ContactView from './pages/ContactView';
import HomeView from './pages/HomeView';
import ServicesView from './pages/ServicesView';
import { Routes, Route } from 'react-router-dom';
import BlankSpace from './components/BlankSpace';
// import TestView from './pages/TestView';
import AboutView from './pages/AboutView';
// import Stylesheet from './components/Stylesheet';
import BookkeepingPlan from './pages/ClientPages/BookkeepingPlan';
import DextDemo from './pages/ClientPages/DextDemo';



function App() {
  

  return (
    <div>
      <BlankSpace/>
      <Navbar/>

      <Routes>
        <Route exact path="/" element={<HomeView />}/>
        <Route exact path="/services" element={<ServicesView />}/>
        <Route path="/contact" element={<ContactView />}/>
        <Route path="/about" element={<AboutView />} />
        <Route path="/hcdbookkeepingplan" element={<BookkeepingPlan />}/>
        <Route path="/asd-dext-demo" element={<DextDemo />}/>

        {/* <Route path="/test" element={<TestView />}/> */}

      </Routes>
      <ContactForm/>
      <Footer/>
      {/* <Stylesheet/> */}
      
      
    </div>
  );
}

export default App;

