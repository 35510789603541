import ServicesHero from '../components/services/servicesHero';



const ServicesView = () => {
  
    return (
      <div>
       <ServicesHero />
       

      </div>
         
    )
  };

  export default ServicesView;